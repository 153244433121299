<template>
  <div class="navbar-container d-flex content align-items-center" v-if="render">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <div
        class="d-flex justify-content-center align-items-center"
        v-if="userType != 'Admin::User'"
      >
        <div id="portalHeaderTitle" class="d-flex">
          <div class="d-flex flex-column justify-content-end ">
            Portal do
            <h4 class="p-0 mb-0">{{ portalByRoute.title }}</h4>
          </div>
          <b-img :src="portalByRoute.icon" class="ml-1" />
          <div v-if="!!switchPortalData.portal" class="d-flex ml-3 align-items-end">
            <span id="changePortalLink" @click="switchPortal">Acessar portal do {{switchPortalData.portal}} <i class="feather icon-shuffle"></i></span>
          </div>
        </div>
      </div>
      <b-tabs id="admin-tabs" align="left" v-else class="mb-0 text-dark">
        <b-tab
          :active="currentSubMenu('assignor')"
          @click="goToSubMenu('assignor')"
          title="Cedente"
        />
        <b-tab
          :active="currentSubMenu('investor')"
          @click="goToSubMenu('investor')"
          title="Investidor"
        />
        <b-tab
          :active="currentSubMenu('provider')"
          @click="goToSubMenu('provider')"
          title="Prestador de serviço"
        />
      </b-tabs>
      <!-- Bookmarks Container -->
      <!-- <bookmarks /> -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <locale /> -->
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <!-- <search-bar /> -->
      <!-- <cart-dropdown /> -->
      <!-- <notification-dropdown /> -->
      <!-- <user-dropdown /> -->
      <user-data />
      <nav-bar-divisor />
      <logout-button />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BTabs, BTab, BImg, BLink, BNavbarNav } from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import LogoutButton from './components/LogoutButton'
import UserData from './components/UserData'
import NavBarDivisor from './components/NavBarDivisor'

export default {
  components: {
    BTabs,
    BTab,
    BImg,
    BLink,
    LogoutButton,
    UserData,
    NavBarDivisor,
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown
  },
  data() {
    return {
      render: true,
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    currentSubMenu(subMenu) {
      return this.$route.name?.includes(subMenu)
    },
    goToSubMenu(subMenu) {
      this.$router.push({ name: `admin.${subMenu}.dashboard` })
    },
    currentPortal(){
      // Pega o portal baseado no path da url
      console.log(this.$route.path)
      const portal = this.$route.path.split('/')[1]
      return {
        admin: 'admin',
        investidor: 'investor',
        cedente: 'assignor',
        fornecedor: 'provider',
        prestadordeservico: 'provider'
      }[portal]
    },
    switchPortal() {
      this.$router.push({name: this.switchPortalData.route})
    }
  },
  computed: {
    portalByRoute() {
      const types = {
        'admin': {
          title: 'Administrador',
          icon: require('@/assets/images/portal-investidor-icon.svg'),
        },
        'investor': {
          title: 'Investidor',
          icon: require('@/assets/images/portal-investidor-icon.svg'),
        },
        'assignor': {
          title: 'Cedente',
          icon: require('@/assets/images/portal-investidor-icon.svg'),
        },
        'provider': {
          title: 'Prestador de Serviço',
          icon: require('@/assets/images/portal-investidor-icon.svg'),
        },
      }
      return (
        types[this.currentPortal()] || {
          title: 'Administrador',
          icon: require('@/assets/images/portal-investidor-icon.svg'),
        }
      )
    },
    switchPortalData(){
      const userType =  this.userType
      const portal = this.currentPortal()
      let newPortal = {portal: null, route: null}
      
      if(!(userType == 'Cedente::User' && this.userData?.portal_investidor)) return newPortal
     
      if(portal == 'assignor'){
        newPortal.portal = 'Investidor'
        newPortal.route = 'investor.emissions'
      }else{
        newPortal.portal = 'Cedente'
        newPortal.route = 'assignor.emissions'
      }
      
      return newPortal
    },
    userData() {
      return this.$store.state.auth.userData
    },
    userType() {
      return this.userData.type
    },
  },
}
</script>

<style lang="scss">
#portalHeaderTitle {
  #changePortalLink {
    cursor: pointer;
    font-weight: 500;
    color: #C9794A;
  }
}
#admin-tabs {
  ul {
    margin: 0px;
    li {
      a {
        &.active {
          color: #000;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
