<template>
  <b-row v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="content-header px-2 mb-2">
    <!-- Content Left -->
    <b-col class="content-header-left" cols="12" md="9">
      <b-row class="breadcrumbs-top">
        <b-col cols="12" class="d-flex align-items-center">
          <h1
            class="float-left pr-1 mb-0"
            :class="{ 'content-header-title': !$route.meta.skipGoToHome }"
          >
            {{ $route.meta.pageTitle }}
          </h1>
          <div class="breadcrumb-wrapper" v-if="!$route.meta.skipGoToHome">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
        <b-col cols="12" class="d-flex align-items-center" v-if="$route.meta.pageSubtitle">
          <h4 class="float-left pr-1 mt-1 mb-0">
            {{ $route.meta.pageSubtitle }}
          </h4>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col
      v-if="$route.meta.actions?.length"
      class="content-header-right text-md-right d-md-block d-none"
      md="3"
      cols="12"
    >
      <b-button
        v-for="action in $route.meta.actions"
        :key="action.title"
        :variant="action.variant"
        :to="{ name: action.routeName }"
        :class="action.classes"
      >
        <feather-icon :icon="action.icon" class="mr-50" v-if="action.icon" />
        <span class="align-middle">{{ action.title }}</span>
      </b-button>
    </b-col>

    <!-- Content Right -->
    <b-col
      v-else
      class="content-header-right d-flex justify-content-end align-content-center"
      md="3"
      cols="12"
    >
      <div
        class="d-flex align-items-center"
        v-if="$route.meta.skipGoToHome && backButtonLabel != 'Voltar'"
      >
        <b-button
          size="sm"
          variant="outline"
          class="back-button d-flex align-items-center text-dark"
          @click="$router.push({ name: emissionsPath })"
        >
          <feather-icon icon="ArrowLeftIcon" size="24" class="mr-1" />
          <span>{{ backButtonLabel }}</span>
        </b-button>
      </div>
      <div class="d-flex justify-content-end align-items-center cursor-pointer">
        <b-button
          variant="outline-primary"
          @click="$router.push({ name: emissionsPath })"
          v-if="$route.name.includes('details')"
          class="d-flex justify-content-between align-items-center bg-white"
        >
          <feather-icon icon="ArrowLeftIcon" size="20" class="mr-1" />
          <span>{{ backButtonLabel }}</span>
        </b-button>
      </div>
      <div
        class="d-flex justify-content-end align-items-center cursor-pointer ml-2"
        @click="goToWhatsApp"
        v-if="$route.meta.showWhatsAppAction"
      >
        <span>Fale pelo<br />WhatsApp</span>
        <b-img src="@/assets/images/whats-app.svg" class="ml-1" />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BImg,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BBreadcrumb,
  BBreadcrumbItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BImg,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BBreadcrumb,
    BBreadcrumbItem,
  },
  methods: {
    goToWhatsApp() {
      window.open('https://api.whatsapp.com/send?phone=551130343417', '_blank')
    },
  },
  computed: {
    emissionsPath() {
      const userTypes = {
        'Admin::User': 'admin.assignor.dashboard',
        'Investidor::User': 'investor.emissions',
        'Cedente::User': 'assignor.emissions',
        'Fornecedor::User': 'provider.emissions',
      }

      const userType = this.$store.state.auth.userData.type

      if (
        userType === 'Admin::User' &&
        this.$route.meta.navActiveLink === 'admin.provider.dashboard'
      ) {
        return this.$route.meta.navActiveLink
      }

      return userTypes[userType]
    },

    backButtonLabel() {
      const GERoutes = [
        'admin.assignor.economicGroup.companies',
        'admin.assignor.economicGroup.users',
        'admin.assignor.economicGroup.invites',
        'admin.provider.economicGroup.companies',
        'admin.provider.economicGroup.users',
        'admin.provider.economicGroup.invites',
      ]

      return GERoutes.includes(this.$route.name) ? 'Ver Grupos Econômicos' : 'Voltar'
    },
  },
}
</script>

<style lang="scss">
.back-button {
  background-color: transparent !important;
  border: 1px solid #1eb499 !important;
}
</style>
