<template>
  <div>
    <svg
      v-if="icon == 'CustomCalc'"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3"
        y="2.24983"
        width="12"
        height="13.5"
        rx="2"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="3"
        y="2.24983"
        width="12"
        height="13.5"
        rx="2"
        stroke="currentColor"
        stroke-opacity="0.2"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="6"
        y="5.24983"
        width="6"
        height="2.25"
        rx="1"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="6"
        y="5.24983"
        width="6"
        height="2.25"
        rx="1"
        stroke="currentColor"
        stroke-opacity="0.2"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 10.4998V10.5073"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 10.4998V10.5073"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 10.4998V10.5073"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 12.7498V12.7573"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 12.7498V12.7573"
        stroke="currentColor"
        stroke-opacity="0.2"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 12.7498V12.7573"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 12.7498V12.7573"
        stroke="currentColor"
        stroke-opacity="0.2"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 12.7498V12.7573"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 12.7498V12.7573"
        stroke="currentColor"
        stroke-opacity="0.2"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <svg
      v-else-if="icon == 'CustomNotasERecibosIcon'"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 15.75V3.75C3.75 2.92157 4.42157 2.25 5.25 2.25H12.75C13.5784 2.25 14.25 2.92157 14.25 3.75V15.75L12 14.25L10.5 15.75L9 14.25L7.5 15.75L6 14.25L3.75 15.75"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5 6.75C10.9142 6.75 11.25 6.41421 11.25 6C11.25 5.58579 10.9142 5.25 10.5 5.25V6.75ZM9.375 8.25V7.5V8.25ZM9.375 10.5V11.25V10.5ZM7.5 9.75C7.08579 9.75 6.75 10.0858 6.75 10.5C6.75 10.9142 7.08579 11.25 7.5 11.25V9.75ZM9.75 10.5C9.75 10.0858 9.41421 9.75 9 9.75C8.58579 9.75 8.25 10.0858 8.25 10.5H9.75ZM8.25 11.625C8.25 12.0392 8.58579 12.375 9 12.375C9.41421 12.375 9.75 12.0392 9.75 11.625H8.25ZM9.75 4.875C9.75 4.46079 9.41421 4.125 9 4.125C8.58579 4.125 8.25 4.46079 8.25 4.875H9.75ZM8.25 6C8.25 6.41421 8.58579 6.75 9 6.75C9.41421 6.75 9.75 6.41421 9.75 6H8.25ZM10.5 5.25H8.625V6.75H10.5V5.25ZM8.625 5.25C7.58947 5.25 6.75 6.08947 6.75 7.125H8.25C8.25 6.91789 8.41789 6.75 8.625 6.75V5.25ZM6.75 7.125C6.75 8.16053 7.58947 9 8.625 9V7.5C8.41789 7.5 8.25 7.33211 8.25 7.125H6.75ZM8.625 9H9.375V7.5H8.625V9ZM9.375 9C9.58211 9 9.75 9.16789 9.75 9.375H11.25C11.25 8.33947 10.4105 7.5 9.375 7.5V9ZM9.75 9.375C9.75 9.58211 9.58211 9.75 9.375 9.75V11.25C10.4105 11.25 11.25 10.4105 11.25 9.375H9.75ZM9.375 9.75H7.5V11.25H9.375V9.75ZM8.25 10.5V11.625H9.75V10.5H8.25ZM8.25 4.875V6H9.75V4.875H8.25Z"
        fill="currentColor"
      />
    </svg>

    <svg
      v-else-if="icon == 'CustomDatabaseIcon'"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="10.6342"
        cy="4.99976"
        rx="6.66667"
        ry="2.5"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.71753 4.99976C4.71753 4.58554 4.38174 4.24976 3.96753 4.24976C3.55332 4.24976 3.21753 4.58554 3.21753 4.99976H4.71753ZM3.21753 11.6664C3.21753 12.0806 3.55332 12.4164 3.96753 12.4164C4.38174 12.4164 4.71753 12.0806 4.71753 11.6664H3.21753ZM8.25408 11.5774C7.84454 11.5153 7.46224 11.797 7.40019 12.2066C7.33814 12.6161 7.61983 12.9984 8.02937 13.0605L8.25408 11.5774ZM18.0509 4.99976C18.0509 4.58554 17.7151 4.24976 17.3009 4.24976C16.8867 4.24976 16.5509 4.58554 16.5509 4.99976H18.0509ZM3.21753 4.99976V11.6664H4.71753V4.99976H3.21753ZM8.02937 13.0605C8.83834 13.183 9.71679 13.2498 10.6342 13.2498V11.7498C9.78832 11.7498 8.98511 11.6881 8.25408 11.5774L8.02937 13.0605ZM10.6342 13.2498C12.5412 13.2498 14.3011 12.9612 15.6115 12.4698C16.2639 12.2252 16.844 11.9158 17.2745 11.5341C17.7041 11.1533 18.0509 10.6365 18.0509 9.99976H16.5509C16.5509 10.0535 16.5246 10.1944 16.2794 10.4117C16.0351 10.6283 15.6389 10.8576 15.0849 11.0653C13.9823 11.4787 12.4089 11.7498 10.6342 11.7498V13.2498ZM18.0509 9.99976V4.99976H16.5509V9.99976H18.0509Z"
        fill="currentColor"
      />
      <path
        d="M10.0384 16.7394C9.62447 16.7243 9.27663 17.0476 9.26149 17.4615C9.24634 17.8754 9.56963 18.2233 9.98357 18.2384L10.0384 16.7394ZM18.051 9.99976C18.051 9.58554 17.7152 9.24976 17.301 9.24976C16.8868 9.24976 16.551 9.58554 16.551 9.99976H18.051ZM2.30103 15.0831C1.88681 15.0831 1.55103 15.4189 1.55103 15.8331C1.55103 16.2473 1.88681 16.5831 2.30103 16.5831V15.0831ZM8.13436 16.5831C8.54857 16.5831 8.88436 16.2473 8.88436 15.8331C8.88436 15.4189 8.54857 15.0831 8.13436 15.0831V16.5831ZM6.16469 12.8028C5.8718 12.5099 5.39692 12.5099 5.10403 12.8028C4.81114 13.0957 4.81114 13.5705 5.10403 13.8634L6.16469 12.8028ZM8.13436 15.8331L8.66469 16.3634C8.95758 16.0705 8.95758 15.5957 8.66469 15.3028L8.13436 15.8331ZM5.10403 17.8028C4.81114 18.0957 4.81114 18.5705 5.10403 18.8634C5.39692 19.1563 5.8718 19.1563 6.16469 18.8634L5.10403 17.8028ZM9.98357 18.2384C10.1989 18.2463 10.4165 18.2498 10.6343 18.2498V16.7498C10.4322 16.7498 10.2331 16.7465 10.0384 16.7394L9.98357 18.2384ZM10.6343 18.2498C12.5413 18.2498 14.3012 17.9612 15.6116 17.4698C16.264 17.2252 16.8441 16.9158 17.2746 16.5341C17.7042 16.1533 18.051 15.6365 18.051 14.9998H16.551C16.551 15.0535 16.5247 15.1944 16.2795 15.4117C16.0352 15.6283 15.639 15.8576 15.0849 16.0653C13.9824 16.4787 12.409 16.7498 10.6343 16.7498V18.2498ZM18.051 14.9998V9.99976H16.551V14.9998H18.051ZM2.30103 16.5831H8.13436V15.0831H2.30103V16.5831ZM5.10403 13.8634L7.60403 16.3634L8.66469 15.3028L6.16469 12.8028L5.10403 13.8634ZM7.60403 15.3028L5.10403 17.8028L6.16469 18.8634L8.66469 16.3634L7.60403 15.3028Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'CCIcon',
  props: {
    icon: {
      required: true,
      type: String,
    },
  },
}
</script>
